import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/managerDashboard/LearnerPracticeSubmissions.module.css";

export default {
  "breadcrumbs": "LearnerPracticeSubmissions-module__breadcrumbs_2ArVWq__",
  "container": "LearnerPracticeSubmissions-module__container_2ArVWq__",
  "emailContainer": "LearnerPracticeSubmissions-module__emailContainer_2ArVWq__",
  "learnerNameContainer": "LearnerPracticeSubmissions-module__learnerNameContainer_2ArVWq__",
  "submissionTag": "LearnerPracticeSubmissions-module__submissionTag_2ArVWq__",
  "subtopicContainer": "LearnerPracticeSubmissions-module__subtopicContainer_2ArVWq__",
  "topicContainer": "LearnerPracticeSubmissions-module__topicContainer_2ArVWq__"
};
      