import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Layout, 
  Col,
  Typography,
  Table,
  Tag
} from 'antd';

const { Column } = Table;
const { Content } = Layout;
const { Title } = Typography;

import { learnerPracticeSubmissionsPath } from '../../lib/urls';
import styles from '../../../stylesheets/managerDashboard/AllLearners.module.css';

const AllLearners = () => {
  const navigate = useNavigate();
  const [topicAssignments, setTopicAssignments] = useState(null);

  useEffect(() => {
    const fetchAllLearners = async () => {
      const response = await axios.get('/api/administrator/topic-assignments');
      setTopicAssignments(response.data.topic_assignments);
    }

    fetchAllLearners();
  }, []);

  const learnerTopicAssignments = () => {
    return topicAssignments.map((topicAssignment, index) => {
      return {
        key: index,
        name: topicAssignment.full_name,
        course: topicAssignment.topic,
        courseProgress: { 
          total: topicAssignment.practice_scenarios_total,
          completed: topicAssignment.practice_scenarios_completed,
        },
        userToken: topicAssignment.user_token
      };
    });
  }

  const renderCourseProgressTag = (courseProgress) => {
    const total = courseProgress.total;
    const completed = courseProgress.completed;

    if (total === completed) {
      return <Tag color='green'>Completed</Tag>;
    } else if (completed === 0) {
      return <Tag color='red'>Not Started</Tag>;
    } else {
      return <Tag color='blue'>In Progress</Tag>;
    }
  }

  if (topicAssignments === null) {
    return <div/>;
  }

  return(
    <Layout>
      <Content
        className={styles.container}
        align="stretch"
      >
        <Col
          span={18}
          offset={2}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <div className={styles.titleContainer}>
            <Title level={3}>All Learners</Title>
          </div>
          <Table 
            dataSource={learnerTopicAssignments()}
            pagination={false}
            onRow={(record) => ({
              onClick: () => navigate(learnerPracticeSubmissionsPath(record.userToken)),
              style: { cursor: 'pointer' }
            })}
          >
            <Column 
              title='Name' 
              dataIndex='name'
              key='name'
            />
            <Column 
              title='Course'
              dataIndex='course'
              key='course'
            />
            <Column 
              title='Course Progress'
              dataIndex='courseProgress' 
              key='courseProgress'
              render={renderCourseProgressTag}
            />
          </Table>
        </Col>
      </Content>
    </Layout>
  );
};

export default AllLearners;