import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
const { Header } = Layout;

import AuthorizationManager from '../lib/AuthorizationManager';
import styles from '../../stylesheets/App.module.css';

const TRAINING_PATH = '/';
const MANAGER_DASHBOARD_PATH = '/manager-dashboard';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await axios.get('/api/user/fetch-current-user');
      const currentUser = response.data.current_user;

      if (currentUser == null) {
        navigate('/login');
      } else {
        setLoadingCurrentUser(false);
        setUserPermissions(currentUser.permissions);
      }
    }

    fetchCurrentUser();
  }, []);

  const handleLogout = async () => {
    await axios.post('/api/logout');
    navigate('/login');
  }

  const learnerItems = [
    {
      label: 'Training',
      key: TRAINING_PATH,
    }
  ];

  const accountAdminItems = [
    {
      label: 'Manager Dashboard',
      key: MANAGER_DASHBOARD_PATH,
    }
  ];

  const learnerLogOutItem = [
    {
      type: 'divider',
      style: { flexGrow: 1, order: 2 },
    },
    {
      label: 'Log Out',
      key: 'Log Out',
      icon: <LogoutOutlined />,
      onClick: handleLogout
    }
  ];

  const accountAdminLogOutItem = [
    {
      type: 'divider',
      style: { flexGrow: 1, order: 3 },
    },
    {
      label: 'Log Out',
      key: 'Log Out',
      icon: <LogoutOutlined />,
      onClick: handleLogout
    }
  ];

  const headerNavItems = () => {
    const authorizationManager = new AuthorizationManager();

    if (authorizationManager.hasManagerDashboardAccess(userPermissions)) {
      return learnerItems.concat(accountAdminItems, accountAdminLogOutItem);
    } else {
      return learnerItems.concat(learnerLogOutItem);
    }
  }

  const selectedNavItemKey = () => {
    const currentPath = location.pathname;
    const selectedManagerDashboard = currentPath.startsWith(MANAGER_DASHBOARD_PATH);

    if (selectedManagerDashboard) {
      return MANAGER_DASHBOARD_PATH;
    } else {
      return TRAINING_PATH;
    }
  }

  if (isLoadingCurrentUser) {
    return <div/>;
  }


  return (
    <Layout style={{
      height: '100%',
    }}>
      <Header
        className={styles.header}
      >
        <div className={styles.logoContainer}>
          <img 
            src="https://development-instruct.s3.us-east-2.amazonaws.com/jolly-logo.png"
            className={styles.logo}
          />
        </div>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedNavItemKey()]}
          onClick={(item) => navigate(item.key)}
          items={headerNavItems()}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
      </Header>
      <Outlet />
    </Layout>
  );
};

export default App;