import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/managerDashboard/PracticeSubmission.module.css";

export default {
  "breadcrumbs": "PracticeSubmission-module__breadcrumbs_6a-hdG__",
  "container": "PracticeSubmission-module__container_6a-hdG__",
  "contentHierarchy": "PracticeSubmission-module__contentHierarchy_6a-hdG__",
  "details": "PracticeSubmission-module__details_6a-hdG__",
  "detailsTitle": "PracticeSubmission-module__detailsTitle_6a-hdG__",
  "feedback": "PracticeSubmission-module__feedback_6a-hdG__",
  "learnerCommentsTitle": "PracticeSubmission-module__learnerCommentsTitle_6a-hdG__",
  "link": "PracticeSubmission-module__link_6a-hdG__",
  "negativeFeedbackIcon": "PracticeSubmission-module__negativeFeedbackIcon_6a-hdG__",
  "negativeRatingContainer": "PracticeSubmission-module__negativeRatingContainer_6a-hdG__",
  "positiveFeedbackIcon": "PracticeSubmission-module__positiveFeedbackIcon_6a-hdG__",
  "positiveRatingContainer": "PracticeSubmission-module__positiveRatingContainer_6a-hdG__",
  "practiceScenario": "PracticeSubmission-module__practiceScenario_6a-hdG__",
  "practiceScenarioHeader": "PracticeSubmission-module__practiceScenarioHeader_6a-hdG__",
  "ratingTitle": "PracticeSubmission-module__ratingTitle_6a-hdG__",
  "specificIssues": "PracticeSubmission-module__specificIssues_6a-hdG__",
  "specificIssuesTitle": "PracticeSubmission-module__specificIssuesTitle_6a-hdG__",
  "submittedBy": "PracticeSubmission-module__submittedBy_6a-hdG__",
  "submittedByContainer": "PracticeSubmission-module__submittedByContainer_6a-hdG__",
  "submittedOn": "PracticeSubmission-module__submittedOn_6a-hdG__",
  "submittedOnContainer": "PracticeSubmission-module__submittedOnContainer_6a-hdG__",
  "subtopic": "PracticeSubmission-module__subtopic_6a-hdG__",
  "subtopicHeader": "PracticeSubmission-module__subtopicHeader_6a-hdG__",
  "tag": "PracticeSubmission-module__tag_6a-hdG__",
  "titleContainer": "PracticeSubmission-module__titleContainer_6a-hdG__",
  "topic": "PracticeSubmission-module__topic_6a-hdG__",
  "topicHeader": "PracticeSubmission-module__topicHeader_6a-hdG__",
  "transcript": "PracticeSubmission-module__transcript_6a-hdG__",
  "transcriptTitle": "PracticeSubmission-module__transcriptTitle_6a-hdG__"
};
      