import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Layout, Typography, Col, Breadcrumb, Tag } from 'antd';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
const { Content } = Layout;
const { Title } = Typography;

import { allLearners, learnerPracticeSubmissionsPath, practiceScenarioPath } from '../../lib/urls';
import { LearnerFeedbackQuality } from '../../lib/constants'
import styles from '../../../stylesheets/managerDashboard/PracticeSubmission.module.css';

const PracticeSubmission = () => {
  const { learnerToken, practiceAttemptToken } = useParams();
  const [practiceSubmission, setPracticeSubmission] = useState(null);

  useEffect(() => {
    const fetchPracticeSubmission = async () => {
      const response = await axios.get(`/api/practice-attempt/fetch-submission?user_token=${learnerToken}&practice_attempt_token=${practiceAttemptToken}`);
      setPracticeSubmission(response.data)
    }

    fetchPracticeSubmission();
  }, []);

  const learnerFeedbackRating = () => {
    const learnerFeedbackRating = practiceSubmission.feedback.learner_review_quality;

    switch(learnerFeedbackRating) {
      case (LearnerFeedbackQuality.POSITIVE):
        return(
          <div className={styles.positiveRatingContainer}>
            <div className={styles.positiveFeedbackIcon} />
            Great feedback!
          </div>
        );
      case (LearnerFeedbackQuality.NEGATIVE):
        return(
          <div className={styles.negativeRatingContainer}>
            <div className={styles.negativeFeedbackIcon} />
            Something's wrong
          </div>
        )
      default:
        return '—';
    }
  }

  const specificIssues = () => {
    const specificIssues = practiceSubmission.feedback.learner_issue_tags;

    if (specificIssues.length === 0) {
      return '—';
    }

    return specificIssues.map((tag) => (
      <Tag key={tag} className={styles.tag}>
        {tag}
      </Tag>
    ))
  }

  const learnerFeedbackDetails = () => {
    const details = practiceSubmission.feedback.learner_review_details;

    if (details === '' || details === null) {
      return '—'
    } else {
      return details;
    }
  }

  if (practiceSubmission === null) {
    return <div/>;
  }

  return(
    <Layout>
      <Content
        className={styles.container}
        align="stretch"
      >
        <Col
          span={18}
          offset={2}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Breadcrumb
            items={[
              {
                title: <Link to={allLearners()}>All Learners</Link>,
              },
              {
                title: <Link to={learnerPracticeSubmissionsPath(learnerToken)}>
                  {practiceSubmission.user.display_name}
                </Link>
              },
              {
                title: practiceSubmission.practice_scenario.title,
              },
            ]}
            className={styles.breadcrumbs}
          />
          <div className={styles.titleContainer}>
            <Title level={3}>Submission — {practiceSubmission.practice_scenario.title}</Title>
          </div>
          <div className={styles.submittedByContainer}>
            <span className={styles.submittedBy}>
              Submitted By {practiceSubmission.user.display_name}
            </span>
          </div>
          <div className={styles.submittedOnContainer}>
            <span className={styles.submittedOn}>
              On {practiceSubmission.practice_attempt.submitted_at}
            </span>
          </div>
          <div className={styles.contentHierarchy}>
            <div>
              <div className={styles.topicHeader}>
                Topic
              </div>
              <div className={styles.topic}>
                {practiceSubmission.topic.title}
              </div>
            </div>

            <div>
              <div className={styles.subtopicHeader}>
                Subtopic
              </div>
              <div className={styles.subtopic}>
                {practiceSubmission.subtopic.title}
              </div>
            </div>

            <div>
              <div className={styles.practiceScenarioHeader}>
                Practice Scenario
              </div>
              <div className={styles.practiceScenario}>
                <Link
                  to={{ pathname: practiceScenarioPath(practiceSubmission.subtopic.token, practiceSubmission.practice_scenario.token) }}
                  target='_blank'
                  className={styles.link}
                >
                  {practiceSubmission.practice_scenario.title}
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.transcriptTitle}>
            <Title level={4}>Transcript of Learner's Practice Session</Title>
          </div>
          <div className={styles.transcript}>
            {practiceSubmission.practice_attempt.transcript}
          </div>
          <div className={styles.feedback}>
            <Markdown
              className='feedback-container'
              skipHtml={false}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {practiceSubmission.feedback.content}
            </Markdown>
          </div>
          <div className={styles.learnerCommentsTitle}>
            <Title level={4}>Learner’s Comments on the Feedback</Title>
          </div>

          <div className={styles.ratingTitle}>
            Rating
          </div>
          <div className={styles.rating}>
            {learnerFeedbackRating()}
          </div>

          <div className={styles.specificIssuesTitle}>
            Specific Issues
          </div>
          <div className={styles.specificIssues}>
            {specificIssues()}
          </div>

          <div className={styles.detailsTitle}>
            Details
          </div>
          <div className={styles.details}>
            {learnerFeedbackDetails()}
          </div>
        </Col>
      </Content>
    </Layout>
  );
};

export default PracticeSubmission;