export const VideoPlayerStates = {
  NOT_PLAYING: 'NOT_PLAYING',
  PLAYING: 'PLAYING',
  FINISHED: 'FINISHED',
};

export const RecordingStates = {
  NOT_STARTED: 'NOT_STARTED',
  COUNTING_DOWN: 'COUNTING_DOWN',
  RECORDING: 'RECORDING',
  PAUSED: 'PAUSED',
  SUBMITTING: 'SUBMITTING',
}

export const TopicStatuses = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
}

export const SubtopicStatuses = {
  INCOMPLETE: 'INCOMPLETE',
  COMPLETE: 'COMPLETE',
}

export const UnitStatuses = {
  INCOMPLETE: 'INCOMPLETE',
  COMPLETE: 'COMPLETE',
}

export const UnitTypes = {
  WATCH_UNIT: 'WATCH_UNIT',
  PRACTICE_SCENARIO: 'PRACTICE_SCENARIO',
}

export const LoginApiStatusCodes = {
  SUCCESS: 'SUCCESS',
}

export const ResetPasswordApiStatusCodes = {
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  PASSWORDS_DO_NOT_MATCH: 'PASSWORDS_DO_NOT_MATCH',
  INVALID_TOKEN: 'INVALID_TOKEN',
  SUCCESS: 'SUCCESS',
}

export const PasswordManagerFetchUserApiStatusCodes = {
  INVALID_TOKEN: 'INVALID_TOKEN',
  SUCCESS: 'SUCCESS',
}

export const SetInitialPasswordApiStatusCodes = {
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  PASSWORDS_DO_NOT_MATCH: 'PASSWORDS_DO_NOT_MATCH',
  INVALID_TOKEN: 'INVALID_TOKEN',
  SUCCESS: 'SUCCESS',
}

export const ReviewFeedbackStep = {
  QUALITY: 'QUALITY',
  TAGS: 'TAGS',
  DETAILS: 'DETAILS',
  CONFIRMATION_POSITIVE: 'CONFIRMATION_POSITIVE',
  CONFIRMATION_NEGATIVE: 'CONFIRMATION_NEGATIVE',
  NONE: 'NONE',
}

export const DevicePermissionsModal = {
  NONE: 'NONE',
  GRANT_PERMISSIONS: 'GRANT_PERMISSIONS',
  CLICK_ACCEPT: 'CLICK_ACCEPT',
  BROWSER_PERMISSIONS_DENIED: 'BROWSER_PERMISSIONS_DENIED',
  OS_PERMISSIONS_DENIED: 'OS_PERMISSIONS_DENIED',
}

export const DevicePermissionsState = {
  ASK: 'ASK',
  GRANTED: 'GRANTED',
  BROWSER_DENIED: 'BROWSER_DENIED',
  OS_DENIED: 'OS_DENIED',
}

export const PracticeSubmitModalState = {
  HIDE: 'HIDE',
  SHOW_SUBMIT_OPTION: 'SHOW_SUBMIT_OPTION',
  SHOW_SUBMIT_CONFIRMATION: 'SHOW_SUBMIT_CONFIRMATION',
}

export const LearnerFeedbackQuality = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
}